import React from "react";
import Card from "components/Cards/Card";
import ConfigForm from "components/Forms/ConfigForm";
import Layout from "layouts/EmptyLayout";
import { redirect } from "libs/utils";

const form = {
  name: "contact",
  submitName: "Contact",
  //className: "grid grid-cols-1 lg:grid-cols-2 gap-4",
  action: "",
  onSuccess: () => {
    redirect("/thankyou");
  },
  fields: [
    {
      type: "input",
      subType: "email",
      name: "email",
      label: "Email",
      placeholder: "Your email",
      rules: {
        required: true,
        minLength: { value: 5 },
      },
    },
    {
      type: "input",
      subType: "string",
      name: "name",
      label: "Full name",
      placeholder: "Your name",
      defaultValue: "",
      rules: {
        required: true,
        minLength: { value: 5 },
      },
    },
    {
      type: "input",
      subType: "hidden",
      name: "form-name",
      defaultValue: "contact",
      value: "contact",
    },
    {
      type: "textarea",
      name: "message",
      label: "Message",
      placeholder: "Your message",
      rules: {
        required: true,
      },
      rows: 5,
    },
  ],
};

export default function Contact() {
  return (
    <>
      <Layout title="Contact">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full mt-6">
            <div className="w-full lg:w-8/12 px-4">
              <div className="pb-4 lg:pb-10">
                <h1 className="w-full flex m-6 text-4xl justify-center">
                  Contact
                </h1>

                <p className="leading-relaxed mt-1 mb-4"></p>
              </div>

              <Card className="bg-background-300">
                <form name="contact" data-netlify="true" method="POST">
                  <div className="">
                    <div className="w-full my-1 ">
                      <div className="mb-2">
                        <label className="block uppercase" htmlFor="email">
                          Email
                        </label>
                      </div>
                      <input
                        name="email"
                        type="email"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Your email"
                        required={true}
                        minLength={5}
                        aria-invalid="false"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="mb-2">
                        <label className="block uppercase" htmlFor="name">
                          Full name
                        </label>
                      </div>
                      <input
                        name="name"
                        type="string"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Your name"
                        required={true}
                        minLength={5}
                        aria-invalid="false"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="mb-2"></div>
                      <input
                        name="form-name"
                        type="hidden"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder=""
                        aria-invalid="false"
                        value="contact"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="flex mb-2">
                        <label className="block uppercase" htmlFor="message">
                          Message
                        </label>
                      </div>
                      <textarea
                        name="message"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Your message"
                        required={true}
                        rows={5}
                        aria-invalid="false"
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-full text-center mt-6 flex text-sm">
                    <div className="w-full">
                      <div className="w-full text-center">
                        <button
                          className="items-center text-gray-200 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-primary-300 w-full "
                          type="submit"
                        >
                          Contact
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
